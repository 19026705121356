<template>
  <div class='pop-wrap' v-if="value && gameList.length > 0">
    <div class="pop-mask"></div>
    <div class="pop-content">
      <div class="pop-head">
        <div class="pop-tab">
          <div class="pop-tab-item" :class="{active: type==='register'}" @click="handlerToRegister">
            <Icon type="register" style="width: 0.3rem;margin-right: .12rem"/>
            <span>{{  $t('register') }}</span>
          </div>
          <div class="pop-tab-item" :class="{active: type==='login'}" @click="handlerToLogin">
            <Icon type="login" style="width: 0.3rem;margin-right: .12rem"/>
            <span>{{  $t('login') }}</span>
          </div>
          <div class="line" :style="{left: type==='register'?'0%':'50%'}"></div>
        </div>
        <span class="close-line" @click="handleClose">
          <Icon type="close-line" />
        </span>
      </div>
      <div class="login-form" :style="{height: isTransition?'6.35rem':'auto'}">
        <transition-group :name="transitionName" mode="out-in" @before-enter="handleTransition(true)" @after-enter="handleTransition(false)" @before-leave="handleTransition(true)" @after-leave="handleTransition(false)">
          <div v-if="type == 'register'" key="register">
            <PgForm ref="form" :config="registerFormConfig" v-model="loginForm" @keydown.enter.native="enterClick" >
              <template #password="scope" v-if="type == 'register'">
                <div class="password-tip" >
                  <div class="line-row" >
                    <span class="strength">Strength</span>
                    <div :class="['strength-line','line-' + strength]">
                      <span class="line"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </div>
                    <span class="length-tip">{{ $t('length_tip',{lengthRange:'6-18'}) }}</span>
                  </div>
                  <!--              <div class="err-row">-->
                  <!--                <div :class="['err-item',includeUpcase?'success':'']">-->
                  <!--                  <div class="icon-wrap">-->
                  <!--                    <icon :type="includeUpcase?'true':'close'"></icon>-->
                  <!--                  </div>-->
                  <!--                  <span class="err-text">{{ $t('Uppercase') }}</span>-->
                  <!--                </div>-->
                  <!--                <div :class="['err-item',includeLowcase?'success':'']">-->
                  <!--                  <div class="icon-wrap">-->
                  <!--                    <icon :type="includeLowcase?'true':'close'"></icon>-->
                  <!--                  </div>-->
                  <!--                  <span class="err-text">{{ $t('Lowercase') }}</span>-->
                  <!--                </div>-->
                  <!--                <div :class="['err-item',includeNumber?'success':'']">-->
                  <!--                  <div class="icon-wrap">-->
                  <!--                    <icon :type="includeNumber?'true':'close'"></icon>-->
                  <!--                  </div>-->
                  <!--                  <span class="err-text">{{ $t('Numbers') }}</span>-->
                  <!--                </div>-->
                  <!--              </div>-->
                </div>
              </template>
            </PgForm>
            <div >
              <div class="check-wrap">
              <span class="mr01" @click="isRead = !isRead">
                <PgCheck v-model="isRead"></PgCheck>
              </span>
                <span @click="isRead = !isRead">{{ $t('over_tip') }}</span>
                <span class="primary-color" @click.stop="$emit('showUserAgreenment')"> 《{{ $t('User_Agreement') }}》</span>
              </div>
              <div class="login-btn">
                <pg-button type="primary" style="width: 100%;" @click="register" :loading="btnLoading">{{ $t('register')
                  }}</pg-button>
              </div>
              <div class="btns">
                <pg-button type="text" v-if="type == 'register'" @click="handlerToLogin">{{ $t('Login_Now') }}</pg-button>
                <pg-button type="text" v-else @click="handlerToRegister">{{ $t('Register_An_Account') }}</pg-button>
              </div>
            </div>
          </div>
          <div v-else key="login">
            <PgForm ref="form" :config="loginFormConfig" v-model="loginForm" @keydown.enter.native="enterClick" >
              <template #password="scope" v-if="type == 'register'">
                <div class="password-tip" >
                  <div class="line-row" >
                    <span class="strength">Strength</span>
                    <div :class="['strength-line','line-' + strength]">
                      <span class="line"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </div>
                    <span class="length-tip">{{ $t('length_tip',{lengthRange:'6-18'}) }}</span>
                  </div>
                  <!--              <div class="err-row">-->
                  <!--                <div :class="['err-item',includeUpcase?'success':'']">-->
                  <!--                  <div class="icon-wrap">-->
                  <!--                    <icon :type="includeUpcase?'true':'close'"></icon>-->
                  <!--                  </div>-->
                  <!--                  <span class="err-text">{{ $t('Uppercase') }}</span>-->
                  <!--                </div>-->
                  <!--                <div :class="['err-item',includeLowcase?'success':'']">-->
                  <!--                  <div class="icon-wrap">-->
                  <!--                    <icon :type="includeLowcase?'true':'close'"></icon>-->
                  <!--                  </div>-->
                  <!--                  <span class="err-text">{{ $t('Lowercase') }}</span>-->
                  <!--                </div>-->
                  <!--                <div :class="['err-item',includeNumber?'success':'']">-->
                  <!--                  <div class="icon-wrap">-->
                  <!--                    <icon :type="includeNumber?'true':'close'"></icon>-->
                  <!--                  </div>-->
                  <!--                  <span class="err-text">{{ $t('Numbers') }}</span>-->
                  <!--                </div>-->
                  <!--              </div>-->
                </div>
              </template>
            </PgForm>

            <div >
              <div class="row row-sb">
                <div class="row column-c check-wrap" @click="isRemenber = !isRemenber">
                <span class="mr01">
                  <PgCheck v-model="isRemenber"></PgCheck>
                </span>
                  <span>{{ $t('Remmeber_Me') }}</span>
                </div>
                <span class="primary-color f022" @click="$emit('forgot')">{{ $t('forgot_password') }}</span>
              </div>
              <div class="login-btn">
                <pg-button type="primary" style="width: 100%;" @click="verifyForm" :loading="btnLoading">{{ $t('login') }}</pg-button>
              </div>

              <div class="btns">
                <pg-button type="text" @click="handlerToRegister">{{ $t('Register_An_Account') }}</pg-button>
              </div>
            </div>
          </div>


        </transition-group>

      </div>
      <span class="mobile-el">
        <i class="icon-close" @click="handleClose"></i>
      </span>
    </div>
    <codePuzzle :show="verifyShow" @closePop="verifyShow=false" @success="toLogin"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Cookies from 'js-cookie';
import { apiRegister, apiToLogin } from '@/api'
import codePuzzle from "@/components/codePuzzle/index.vue";
export default {
  data() {
    return {
      type: 'register',
      isTransition:false,
      loginForm: {
        name: "",
        password: "",
        password2: "",
        // captcha: '',
        // realname: '',
        // qk_pwd: '',
        // key: '',
      },
      loginFormRule: {
        name: { type: '', content: '' },
        password: { type: '', content: '' },
        password2: { type: '', content: '' },
        captcha: { type: '', content: '' },
      },
      isRead: false,
      imageCode: '',
      validate: false,
      isRemenber: false,
      btnLoading: false,
      verifyShow:false,
      transitionName:'slide-left',
      loginSubmitData:{}
    }
  },
  computed: {
    ...mapState({
      gameList: state => state.home.gameList,
      siteInfo: state => state.home.siteInfo,

    }),
    registerFormConfig() {
      const user = {
        type: 'input',
        prop: "name",
        attr: {
          type: 'text',
          prefix: "user",
          placeholder: this.$t('Username')
        },
        validate: (val) => {
          if (val.length < 4 || val.length >= 16) {
            return this.$t('user_tip')
          }
        },
      }
      const phone = {
        type: 'inputWithSelectFlags',
        prop: "phone",
        selectValue:55,
        attr: {
          type: 'text',
          placeholder: this.$t('phone')
        },
        validate: (val) => {
          if (!val) {
            return 'is required'
          }
        },
      }
      const realname = {
        type: 'input',
        prop: "realname",
        attr: {
          type: 'text',
          prefix: "user",
          placeholder: 'relaname'
        },
        validate(val) {
          if (val.length < 4 || val.length >= 16) {
            return '4-16 caráter bit, suporte em inglês/números'
          }
        },
      }
      const token = {
        type: 'input',
        prop: "token",
        attr: {
          type: 'text',
          prefix: "user",
          placeholder: 'token'
        },
      }
      const password = {
        type: 'input',
        prop: "password",
        attr: {
          type: 'password',
          prefix: "lock",
          showPassword: true,
          placeholder: this.$t('password')
        },
        validate: (val, other) => {
          if (val.length < 6 || val.length > 16) {
            return this.$t('password_tip')
          }
        },
      }
      const password2 = {
        type: 'input',
        prop: "password2",
        attr: {
          type: 'password',
          prefix: "lock",
          placeholder: this.$t('Re_enter_password'),
          showPassword: true
        },
        validate: (val, other) => {
          if (val.length < 6 || val.length > 16) {
            return this.$t('password_tip')
          } else if (val != other.password) {
            return this, this.$t('password_agin_tip')
          }
        },
      }
      return  [user, password, password2]
    },
    loginFormConfig() {
      const user = {
        type: 'input',
        prop: "name",
        attr: {
          type: 'text',
          prefix: "user",
          placeholder: this.$t('Username')
        },
        validate: (val) => {
          if (val.length < 4 || val.length >= 16) {
            return this.$t('user_tip')
          }
        },
      }
      const phone = {
        type: 'inputWithSelectFlags',
        prop: "phone",
        selectValue:55,
        attr: {
          type: 'text',
          placeholder: this.$t('phone')
        },
        validate: (val) => {
          if (!val) {
            return 'is required'
          }
        },
      }
      const realname = {
        type: 'input',
        prop: "realname",
        attr: {
          type: 'text',
          prefix: "user",
          placeholder: 'relaname'
        },
        validate(val) {
          if (val.length < 4 || val.length >= 16) {
            return '4-16 caráter bit, suporte em inglês/números'
          }
        },
      }
      const token = {
        type: 'input',
        prop: "token",
        attr: {
          type: 'text',
          prefix: "user",
          placeholder: 'token'
        },
      }
      const password = {
        type: 'input',
        prop: "password",
        attr: {
          type: 'password',
          prefix: "lock",
          showPassword: true,
          placeholder: this.$t('password')
        },
        validate: (val, other) => {
          if (val.length < 6 || val.length > 16) {
            return this.$t('password_tip')
          }
        },
      }
      const password2 = {
        type: 'input',
        prop: "password2",
        attr: {
          type: 'password',
          prefix: "lock",
          placeholder: this.$t('Re_enter_password'),
          showPassword: true
        },
        validate: (val, other) => {
          if (val.length < 6 || val.length > 16) {
            return this.$t('password_tip')
          } else if (val != other.password) {
            return this, this.$t('password_agin_tip')
          }
        },
      }
      return [user, password]
    },

    includeLowcase() {
      const password = this.loginForm.password;
      return /[a-z]/.test(password)
    },
    includeNumber(){
      const password = this.loginForm.password;
      return /[0-9]/.test(password)
    },
    includeUpcase(){
      const password = this.loginForm.password;
      return /[A-Z]/.test(password)
    },
    includeSpecialChar() {
      const password = this.loginForm.password;
      return /[^a-zA-Z0-9]/.test(password);
    },
    strength() {
      const password = this.loginForm.password;
      let active = 0;

      if (password.length>0 && password.length < 8) {
        active = 1; // 密码长度小于8，强度等级为1
      } else {
        let hasLower = this.includeLowcase;
        let hasUpper = this.includeUpcase;
        let hasNumber = this.includeNumber;
        let hasSpecial = this.includeSpecialChar;

        if (hasLower && hasUpper && hasNumber && hasSpecial) {
          active = 4; // 包含所有类型（大写、小写、数字、特殊字符），强度等级为4
        }else if (hasLower + hasUpper + hasNumber + hasSpecial >= 3) {
          active = 3; // 包含任意三者，强度等级为3
        } else if (hasLower + hasUpper + hasNumber + hasSpecial >= 2) {
          active = 2; // 包含任意两者，强度等级为2
        } else if (hasLower || hasUpper || hasNumber || hasSpecial) {
          active = 1; // 单纯一个，强度等级为1
        }
      }

      return active;
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(val) {
      if (val && this.type === 'login') {
        this.setAcc()
      }else{
        this.resetForm();
      }
    }
  },
  components:{codePuzzle},
  created() {
    const id = this.$route.query.id
    if (id) {
      Cookies.set('id', id, { expires: 36500 });
    }

  },
  methods: {
    resetForm(){
      this.loginForm = {
        name: "",
        password: "",
        password2: "",
        // captcha: '',
        // realname: '',
        // qk_pwd: '',
        // key: '',
      }
    },
    setAcc(){
      let acc = localStorage.getItem('acc');
      if(acc){
        acc = JSON.parse(acc);
        this.loginForm.name = acc.name;
        this.loginForm.password = acc.password;
      }
    },
    handlerToRegister(){
      this.transitionName='slide-right'
      this.type = 'register'
      // this.resetForm();
    },
    handlerToLogin(){
      this.transitionName='slide-left'
      this.type = 'login'
    },
    handleTransition(flag){
      this.isTransition = flag
    },
    handleClose() {
      this.$emit('input', false)
    },

    enterClick() {
      if (this.type == 'register') {
        this.register()
      } else {
        this.toLogin()
      }
    },
    register() {
      this.$refs.form.validate((validate, data) => {
        if (validate) {
          const id = this.$route.query.id || Cookies.get('id');
          data.token = id
          this.btnLoading = true;
          console.log(data)
          apiRegister(data).then(res => {
            this.btnLoading = false;
            if (res.code == 200) {
              const loginQuery = { name: data.name, password: data.password }
              this.btnLoading = true;
              apiToLogin(loginQuery).then(res => {
                this.btnLoading = false;
                if (res.code == 200) {
                  this.handleClose()
                  this.$store.commit('user/SET_MONEY', 0)
                  this.$store.dispatch('user/GetUserInfo')
                  localStorage.setItem('token', res.data.access_token)
                  this.$emit('login')
                }
              }).catch(() => {
                this.btnLoading = false;
              })
            }
          }).catch(() => {
            this.btnLoading = false;
          })
        }
      })
    },
    verifyForm(){
      console.log('siteInfo',this.siteInfo)
      this.$refs.form.validate((validate, data) => {
        if (validate) {
          this.loginSubmitData = data;
          if(this.siteInfo.captcha_open){
            this.verifyShow = true
          }else{
            this.toLogin()
          }
        }
      })
    },
    toLogin() {
      this.verifyShow = false
      this.btnLoading = true;
      apiToLogin(this.loginSubmitData).then(res => {
        this.btnLoading = false;
        if (res.code == 200) {
          this.handleClose()
          if(this.isRemenber){
            localStorage.setItem('acc',JSON.stringify({name:this.loginForm.name,password:this.loginForm.password}))
          }
          this.$store.dispatch('user/GetUserInfo')
          localStorage.setItem('token', res.data.access_token)
          this.$emit('login')
        }
      }).catch(() => {
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.pop-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  .pop-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .45)
  }

  .pop-content {
    width: 6.8rem;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid var(--theme-color-line);
    padding-bottom: 0.2rem;
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .15);

    .pop-head {
      font-size: .3rem;
      text-align: center;
      line-height: 1.02rem;
      color: var(--theme-text-color);
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-color: var(--theme-main-bg-color);
      .pop-tab{
        display: flex;
        margin-bottom: 0.4rem;
        position: relative;
        .pop-tab-item{
          flex: 1;
          display: flex;
          align-items: center;
          font-size: .26rem;
          justify-content: center;
          &.active{
            color: var(--theme-mine-icon-color);
          }
        }
        .line{
          position: absolute;
          width: 30%;
          margin: 0 10%;
          bottom:0;
          transform: translateY(-100%);
          left: 0;
          height: 0.03rem;
          transition: left 0.5s;
          background: var(--theme-mine-icon-color);
        }
      }
    }
    .close-line {
        cursor: pointer;
        position: absolute;
        right: .1rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: .2rem;
        display: none;
        color: var(--theme-text-color);
        height: 0.56rem;
        line-height: .56rem;
        width: 0.56rem;
      }
    .login-form {
      overflow: hidden;
      width: 100%;
      position: relative;
      & > span{
        display: block;
        &>div{
          width: 100%;
          box-sizing: border-box;
          padding: 0 0.4rem;
        }
      }
      .check-wrap {
        font-size: .22rem;
        color: var(--theme-text-color);
        margin-bottom: 0.2rem;
      }

      .password-tip{
        color: var(--theme-text-color-darken);
        font-size: .22rem;
        margin-right: .1rem;
        display: flex;
        flex-direction: column;
        .line-row {
          display: flex;
          margin-bottom: .14rem;
          align-items: center;
          .strength{
            margin-right: .1rem;
          }
          .length-tip{
            color: var(--theme-text-color-lighten);
          }
          .strength-line{
            display: flex;
            align-items: center;
            .line{
              border-radius: .12rem;
              height: .12rem;
              width: .8rem;
              margin-right: .1rem;
              background: var(--theme-color-line);
              transition: all .3s;
            }
            &.line-1{
              .line{
                &:nth-child(1){
                  background: var(--theme-secondary-color-error);
                }
              }
            }
            &.line-2{
              .line{
                &:nth-child(1),&:nth-child(2){
                  background: var(--theme-secondary-color-finance);
                }
              }
            }
            &.line-3{
              .line{
                &:nth-child(1),&:nth-child(2),&:nth-child(3){
                  background: var(--theme-primary-color);
                }
              }
            }
            &.line-4{
              .line{
                &:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4){
                  background: var(--theme-secondary-color-success);
                }
              }
            }
          }
        }
        .err-row{
          margin-bottom: .14rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .err-item{
            display: flex;
            color: var(--theme-secondary-color-error);
            align-items: center;
            font-size: .18rem;
            &.success{
              color: #fff;
              .icon-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--theme-secondary-color-success);
                font-size: .1rem;
              }
            }
            .icon-wrap{
              background: #fff;
              width: 0.18rem;
              height: 0.18rem;
              border-radius: 50%;
            }
            .err-text{
              display: inline-block;
              max-width: .9rem;
              margin-left: 0.1rem;
              margin-right: .3rem;
            }
          }
        }
      }

      .login-btn {
        width: 100%;
        font-size: 0;
        margin-bottom: 0.2rem;
      }

      .btns {
        display: flex;
        justify-content: space-around;
      }
    }

    .icon-close {
      position: absolute;
      display: inline-block;
      width: 0.5984rem;
      height: 0.5984rem;
      left: 50%;
      bottom: -1rem;
      transform: translateX(-50%);
      background-image: var(--bgUrl);
      background-position: -5.1136rem -1.3872rem;
      background-size: 7.072rem 6.5416rem;
    }

  }

}

.pc-page {
  .close-line{
    
    display: inline-block !important;
  }

  .pop-content {
    transform: translate(-50%, -50%) scale(0.8) !important;
  }
}
.slide-left-enter-active, .slide-left-leave-active,
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 0.5s ease;
  position: absolute;
}

.slide-left-enter {
  transform: translateX(100%);
}
.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter{
  transform: translateX(-100%);
}
.slide-right-leave-to {
  transform: translateX(100%);
}
</style>